
export default {
    main : {
        name : 'MainSlideContainer',
        jquerySlideObject : null ,
        left(){
            this.jquerySlideObject.animate({
                left: "0%",
            }, 1000, function () {
                console.log("finish moving Main Slide to the left")
            });
        },
        right(){
            this.jquerySlideObject.animate({
                left: "50%",
            }, 1000, function() {
                console.log("finish moving Main Slide to the right")
            });
        },
        center(callback){
            this.jquerySlideObject.animate({
                left: "25%",
            }, 1000, callback);
        },
        hide(){
            this.jquerySlideObject.hide();
        },
        show(){
            this.jquerySlideObject.show();
        }
    },
    about : {
        name : 'AboutSlideContainer',
        jquerySlideObject : null ,
        left(){
            this.jquerySlideObject.animate({
                left: "0%",
            }, 1000, function() {
                console.log("finish moving About Slide to the left")
            });
        },
        right(){
            this.jquerySlideObject.animate({
                left: "50%",
            }, 1000, function() {
                console.log("finish moving About Slide to the right")
            });
        },
        center(callback){
            this.jquerySlideObject.animate({
                left: "25%",
            }, 1000, callback);
        },
        hide(){
            this.jquerySlideObject.hide();
        },
        show(){
            this.jquerySlideObject.show();
        }
    },
    skills : {
        name : 'SkillsSlideContainer',
        jquerySlideObject : null ,
        left(){
            this.jquerySlideObject.animate({
                left: "0%",
            }, 1000, function() {
                console.log("finish moving Skills Slide to the left")
            });
        },
        right(){

            this.jquerySlideObject.animate({
                left: "50%",
            }, 1000, function() {
                console.log("finish moving Skills Slide to the right")
            });
        },
        center(callback){
            this.jquerySlideObject.animate({
                left: "25%",
            }, 1000, callback);
        },
        hide(){
            this.jquerySlideObject.hide();
        },
        show(){
            this.jquerySlideObject.show();
        }
    },
    projects : {
        name : 'ProjectsSlideContainer',
        jquerySlideObject : null ,
        left(){
            this.jquerySlideObject.animate({
                left: "0%",
            }, 1000, function() {
                console.log("finish moving Projects Slide to the left")
            });
        },
        right(){
            this.jquerySlideObject.animate({
                left: "50%",
            }, 1000, function() {
                console.log("finish moving Projects Slide to the right")
            });
        },
        center(callback){
            this.jquerySlideObject.animate({
                left: "25%",
            }, 1000,callback);
        },
        hide(){
            this.jquerySlideObject.hide();
        },
        show(){
            this.jquerySlideObject.show();
        }
    },
    contact:{
        name : 'ContactSlideContainer',
        jquerySlideObject : null ,
        left(){
            this.jquerySlideObject.animate({
                left: "0%",
            }, 1000, function() {
                console.log("finish moving Contact Slide to the left")
            });
        },
        right(){
            this.jquerySlideObject.animate({
                left: "50%",
            }, 1000, function() {
                console.log("finish moving Contact Slide to the right")
            });
        },
        center(callback){
            this.jquerySlideObject.animate({
                left: "25%",
            }, 1000,callback);
        },
        hide(){
            this.jquerySlideObject.hide();
        },
        show(){
            this.jquerySlideObject.show();
        }
    }
}
