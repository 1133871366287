<template>
    <div id="skillProgress">
        <svg class="radial-progress" :data-percentage="percentage" viewBox="0 0 80 80">
            <circle class="incomplete" cx="40" cy="40" r="25"></circle>
            <circle class="complete" cx="40" cy="40" r="25" style="stroke-dashoffset: 39.58406743523136;"></circle>
            <text class="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">{{percentage}}%</text>
        </svg>
    </div>
</template>

<script>
    export default {
        props:['percentage'],
        mounted(){
            // to set the value of the bar once the components is mounted
            $('svg.radial-progress').each(function( index, value ) {
                // Get percentage of progress
                let percent = $(value).data('percentage');
                // Get radius of the svg's circle.complete
                let radius = $(this).find($('circle.complete')).attr('r');
                // Get circumference (2πr)
                let circumference = 2 * Math.PI * radius;
                // Get stroke-dashoffset value based on the percentage of the circumference
                let strokeDashOffset = circumference - ((percent * circumference) / 100);
                // Transition progress for 1.25 seconds
                $(this).find($('circle.complete')).css('stroke-dashoffset', strokeDashOffset);
            });
        }
    }
</script>

<style scoped>
    #skillProgress{
        padding: 0;
    }
    svg.radial-progress {
        height: auto;
        margin: 0;
        transform: rotate(-90deg);
        width: 100%;
    }

    svg.radial-progress circle {
        fill: rgba(0,0,0,0);
        stroke: #fff;
        stroke-dashoffset: 219.91148575129;
        stroke-width: 5;
    }

    svg.radial-progress circle.incomplete {
        opacity: 0.25;
    }

    svg.radial-progress circle.complete {
        stroke-dasharray: 157.07963267949; /* 2 * PI * Radius , now R = 25*/
    }

    svg.radial-progress text {
        fill: #fff;
        text-anchor: middle;
    }
    svg.radial-progress circle {
        stroke: #FFC0BE;
    }
</style>
