<template>
    <div class="container" id="MainSlideContainer">
        <div id="scrollerHider">
            <div id="PersonalImageContainer">
                    <div id="PersonalImage"></div>
                    <div class="typewriter">
                        <h1 class="text">
                            <span style="color: gray;font-size: 35px;font-weight: lighter">&lt; </span>
                            Mohammed Balhaddad
                            <span style="color: gray;font-size: 32px;font-weight: lighter"> /></span>
                        </h1>
                    </div>
                </div>
            <section id="SlidesButtons">
                <a @click="about"    class="SlideController" id="AboutSlideButton"> <i class="demo-icon icon-child"></i> About <i class="demo-icon icon-child"></i> </a>
                <a @click="skills"   class="SlideController" id="SkillsSlideButton"> <i class="demo-icon icon-connectdevelop"></i> Skills <i class="demo-icon icon-connectdevelop"></i> </a>
                <a @click="projects" class="SlideController" id="ProjectsSlideButton"> <i class="demo-icon icon-code"></i> Projects <i class="demo-icon icon-code"></i> </a>
                <a @click="contact"  class="SlideController" id="ContactSlideButton"> <i class="demo-icon icon-heart"></i> Contact <i class="demo-icon icon-heart"></i></a>
            </section>
            <section id="SocialLinksContainer">
                <a class="SocialLinks"><i class="fab fa-twitter fa-2x"></i></a>
                <a class="SocialLinks"><i class="fab fa-instagram fa-2x"></i></a>
                <a class="SocialLinks"><i class="fab fa-facebook-f fa-2x"></i></a>
                <a class="SocialLinks"><i class="fab fa-youtube fa-2x"></i></a>
                <a class="SocialLinks"><i class="fas fa-envelope fa-2x"></i></a>
            </section>
            <section id="SocialLinksBorderContainer">
                <a href="https://twitter.com/mbailhadadd" class="SocialLinksBorder"></a>
                <a href="https://www.instagram.com/mohammed_b/" class="SocialLinksBorder"></a>
                <a href="https://www.facebook.com/mohammed.balhaddad" class="SocialLinksBorder"></a>
                <a href="https://www.youtube.com/channel/UCCp_VH15H85Dpj8M81O7Nlg" class="SocialLinksBorder"></a>
                <a href="mailto:mb@mbalhaddad.com" class="SocialLinksBorder"></a>
            </section>
            <a id="topButton" v-show="showUpButton" @click="moveToTop"><i class="fas fa-sort-up"></i></a>

        </div>
    </div>
</template>

<script>
    import transitions from '../helpers/SlideTransitions'
    export default {
        data(){
            return {
                /**
                 * 0 -> all in the middle
                 * 1 -> main right , about left
                 * 2 -> skills right , main left
                 * 3 -> main right , projects left
                 * 4 -> contact right , main left
                 */
                SlidesStatus : 0 ,
                showUpButton : false ,
            };
        },
        methods:{
            about() {
                // small screen
                if($(window).width() < 992){
                    $('html, body').animate({
                        'scrollTop': transitions.about.jquerySlideObject.offset().top
                    }, 800);
                }
                // big screen
                else {
                    // check that we are in height resolution monitor
                    let current = this.SlidesStatus;

                    // if some slides are not in the middle , return them to the middle
                    switch (current) {
                        case 0 :
                            // skills in the right , main in the left
                            transitions.about.show();
                            transitions.about.left();
                            transitions.main.right();
                            // update the SlidesStatus
                            this.SlidesStatus = 1;
                            break;
                        case 1 :
                            // main in the right & about in the left
                            transitions.main.center();
                            transitions.about.center(function () {
                                transitions.about.hide();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 0;
                            break;
                        case 2 :
                            // skills in the right , main in the left
                            transitions.main.center();
                            transitions.skills.center(function () {
                                transitions.skills.hide();
                                transitions.about.show();
                                transitions.about.left();
                                transitions.main.right();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 1;
                            break;
                        case 3 :
                            // main in the right , projects in the left
                            transitions.main.center();
                            transitions.projects.center(function () {
                                transitions.projects.hide();
                                transitions.about.show();
                                transitions.about.left();
                                transitions.main.right();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 1;
                            break;
                        case 4 :
                            // contact in the right , main in the left
                            transitions.main.center();
                            transitions.contact.center(function () {
                                transitions.contact.hide();
                                transitions.about.show();
                                transitions.about.left();
                                transitions.main.right();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 1;
                            break;
                    }
                }
            },
            skills(){
                // small screen
                if($(window).width() < 992){
                    $('html, body').animate({
                        'scrollTop': transitions.skills.jquerySlideObject.offset().top
                    }, 800);
                }
                // big screen
                else {
                    // check that we are in height resolution monitor
                    let current = this.SlidesStatus;

                    // if some slides are not in the middle , return them to the middle
                    switch (current) {
                        case 0 :
                            // skills in the right , main in the left
                            transitions.skills.show();
                            transitions.skills.right();
                            transitions.main.left();
                            // update the SlidesStatus
                            this.SlidesStatus = 2;
                            break;
                        case 1 :
                            // main in the right & about in the left
                            transitions.main.center();
                            transitions.about.center(function () {
                                transitions.about.hide();
                                transitions.skills.show();
                                transitions.skills.right();
                                transitions.main.left();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 2;
                            break;
                        case 2 :
                            // skills in the right , main in the left
                            transitions.main.center();
                            transitions.skills.center(function () {
                                transitions.skills.hide();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 0;
                            break;
                        case 3 :
                            // main in the right , projects in the left
                            transitions.main.center();
                            transitions.projects.center(function () {
                                transitions.projects.hide();
                                transitions.skills.show();
                                transitions.skills.right();
                                transitions.main.left();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 2;
                            break;
                        case 4 :
                            // contact in the right , main in the left
                            transitions.main.center();
                            transitions.contact.center(function () {
                                transitions.contact.hide();
                                transitions.skills.show();
                                transitions.skills.right();
                                transitions.main.left();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 2;
                            break;
                    }
                }
            },
            projects(){
                // small screen
                if($(window).width() < 992){
                    $('html, body').animate({
                        'scrollTop': transitions.projects.jquerySlideObject.offset().top
                    }, 800);
                }
                // big screen
                else {
                    // check that we are in height resolution monitor
                    let current = this.SlidesStatus;

                    // if some slides are not in the middle , return them to the middle
                    switch (current) {
                        case 0 :
                            // all in the center , do nothing
                            transitions.projects.show();
                            transitions.projects.left();
                            transitions.main.right();

                            // update the SlidesStatus
                            this.SlidesStatus = 3;
                            break;
                        case 1 :
                            // main in the right & about in the left
                            transitions.main.center();
                            transitions.about.center(function () {
                                transitions.about.hide();
                                transitions.projects.show();
                                transitions.projects.left();
                                transitions.main.right();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 3;
                            break;
                        case 2 :
                            // skills in the right , main in the left
                            transitions.main.center();
                            transitions.skills.center(function () {
                                transitions.skills.hide();
                                transitions.projects.show();
                                transitions.projects.left();
                                transitions.main.right();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 3;
                            break;
                        case 3 :
                            // main in the right , projects in the left
                            transitions.main.center();
                            transitions.projects.center(function () {
                                transitions.projects.hide();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 0;
                            break;
                        case 4 :
                            // contact in the right , main in the left
                            transitions.main.center();
                            transitions.contact.center(function () {
                                transitions.contact.hide();
                                transitions.projects.show();
                                transitions.projects.left();
                                transitions.main.right();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 3;
                            break;
                    }
                }
            },
            contact(){
                // small screen
                if($(window).width() < 992){
                    $('html, body').animate({
                        'scrollTop': transitions.contact.jquerySlideObject.offset().top
                    }, 800);
                }
                // big screen
                else {
                    // check that we are in height resolution monitor
                    let current = this.SlidesStatus;

                    // if some slides are not in the middle , return them to the middle
                    switch (current) {
                        case 0 :
                            // all in the center , do nothing
                            transitions.contact.show();
                            transitions.contact.right();
                            transitions.main.left();
                            // update the SlidesStatus
                            this.SlidesStatus = 4;
                            break;
                        case 1 :
                            // main in the right & about in the left
                            transitions.main.center();
                            transitions.about.center(function () {
                                transitions.about.hide();
                                transitions.contact.show();
                                transitions.contact.right();
                                transitions.main.left();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 4;
                            break;
                        case 2 :
                            // skills in the right , main in the left
                            transitions.main.center();
                            transitions.skills.center(function () {
                                transitions.skills.hide();
                                transitions.contact.show();
                                transitions.contact.right();
                                transitions.main.left();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 4;
                            break;
                        case 3 :
                            // main in the right , projects in the left
                            transitions.main.center();
                            transitions.projects.center(function () {
                                transitions.projects.hide();
                                transitions.contact.show();
                                transitions.contact.right();
                                transitions.main.left();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 4;
                            break;
                        case 4 :
                            // contact in the right , main in the left
                            transitions.main.center();
                            transitions.contact.center(function () {
                                transitions.contact.hide();
                            });
                            // update the SlidesStatus
                            this.SlidesStatus = 0;
                            break;
                    }
                }
            },
            moveToTop(){
                // Move screen to top
                if($(window).width() < 992) {
                    $('html, body').animate({
                        'scrollTop': 0
                    }, 800);
                }
            },
            eventHandlers(){
                // needed objects
                let vue = this ;
                let jWindow = $(window) ;

                /* Detect Scroll for small screens */
                window.onscroll = function () {
                    // make sure we are in small screen
                    if(jWindow.width() < 992) {
                        vue.showUpButton = (window.scrollY >= 30);
                    }
                };

                /* Screen Size Adjustments*/
                window.onresize = function () {
                    // big screen adjustment
                    if(jWindow.width() >= 992) {
                        // in big screen : no top button at all
                        vue.showUpButton = false ;
                    }
                };
            }
        },
        mounted(){
            // get the objects from the DOM and create JQuery Objects and assign them to its slide object in transition
            transitions.main.jquerySlideObject = $("#" + transitions.main.name );
            transitions.about.jquerySlideObject = $("#" + transitions.about.name );
            transitions.skills.jquerySlideObject = $("#" + transitions.skills.name );
            transitions.projects.jquerySlideObject = $("#" + transitions.projects.name );
            transitions.contact.jquerySlideObject = $("#" + transitions.contact.name );

            this.eventHandlers();
        }
    }
</script>

<style scoped>

    /* type writer effect */
    .typewriter{
        margin-top: 30px;
        display: inline-block;
    }
    .typewriter .text {
        overflow: hidden; /* Ensures the content is not revealed until the animation */
        border-right: 0.05em solid blue; /* The typwriter cursor */
        white-space: nowrap; /* Keeps the content on a single line */
        margin: 0 auto; /* Gives that scrolling effect as the typing happens */
        letter-spacing: .01em; /* Adjust as needed */
        animation:
            typing 3.5s steps(50, end),
            blink-caret 1s step-end infinite;
    }
    @keyframes typing {
        from { width: 0 }
        to { width: 100% }
    }
    @keyframes blink-caret {
        from, to { border-color: transparent }
        50% { border-color: rgba(91,91,91,0.48); }
    }

    /* Slide Container */
    #MainSlideContainer{
        background-image: url("../../assets/images/MainBackground.jpg");
    }

    /* Personal Information */
    #PersonalImageContainer {
        padding-top: 60px ;
        text-align: center;
        /*overflow: hidden;*/
    }
    #PersonalImageContainer #PersonalImage{ /*Image*/
        display: block;
        border-radius: 50% ;
        background: url("../../assets/images/cropped-gryed-profile.png") no-repeat center;
        filter: opacity(85%);
        background-size: cover;
        width: 180px;
        height: 180px;
        border: 1px solid lightgray;
        box-shadow: 0 0 10px 1px black;
        margin: 0 auto ;
    }
    #PersonalImageContainer h1{ /*My Name*/
        margin-top: 40px;
        font-weight: bold;
        font-size: 26px;
    }

    /* Common Slide Controller Buttons Properties */
    #SlidesButtons{
        margin: 40px 0 60px;
    }
    #SlidesButtons .SlideController{
        display: block;
        width: 70%;
        height: 90px;
        margin:20px auto;
        text-align: center;
        line-height: 95px;
        background-color: #f7f7f7;
        border: 1px lightgray solid;
        border-radius: 2px;
        font-size: 21px;
        color: grey;
    }
    #SlidesButtons .SlideController i{
        font-size: 21px;
        color: lightgray;
    }
    #SlidesButtons .SlideController:hover{
        border: 1px dimgray solid;
        font-weight: bold ;
        color: dimgray;
    }
    #SlidesButtons #AboutSlideButton:hover {
        border: 1px dimgray solid;
        font-weight: bold ;
        color: dimgray;
    }
    #SlidesButtons #SkillsSlideButton:hover {
        border: 1px #c76969 solid;
        font-weight: bold ;
        color: #c76969;
    }
    #SlidesButtons #ProjectsSlideButton:hover {
        border: 1px #509aaf solid;
        font-weight: bold ;
        color: #509aaf;
    }
    #SlidesButtons #ContactSlideButton:hover {
        border: 1px #20716a solid;
        font-weight: bold ;
        color: #20716a;
    }

    /* social Links */
    #SocialLinksContainer{
        display: block;
        width: 100%;
        height: 50px;
        text-align: center;
    }
    #SocialLinksContainer .SocialLinks{
        display: inline-block;
        width: 50px;
        height: 100%;
        margin:0 15px;
        line-height: 65px;
        text-decoration: none;
        color: lightgrey;
        text-align: center;
    }
    #SocialLinksContainer .SocialLinks:hover{
        border: 1px dimgray solid;
        color: dimgrey;
    }
    #SocialLinksBorderContainer{
        display: block;
        width: 100%;
        height: 50px;
        text-align: center;
        position: relative;
        top:-50px;
    }
    #SocialLinksBorderContainer .SocialLinksBorder{
        display: inline-block;
        width: 50px;
        height: 100%;
        border: 1px lightgray solid;
        margin:0 14px;
        transform: rotate(45deg);
    }
    #SocialLinksBorderContainer .SocialLinksBorder:hover{
        border: 1px dimgray solid;
    }
    .SocialLinksBorder:hover .SocialLinks{
        color: dimgray;
    }

    /* Top Button */
    a#topButton{
        display: block;
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 20px;
        right: 20px;
        border-radius: 50%;
        border: 1px solid white;
        color: white;
        text-align: center;
        font-size: xx-large;
        line-height: 70px;
        z-index: 1000;
    }


    /* Changes in different views*/
    @media screen and (min-width: 992px  ) {
        /* Slide Container */
        #MainSlideContainer{
            width:50%;
            position: absolute;
            left: 25%;
            z-index: 4;
            overflow: hidden;
            height: 100%;
        }
        #scrollerHider{
            overflow: auto;
            width:100%;
            height: 100%;
            position: absolute;
            padding-right: 20px;
            padding-top: 20px;
        }
    }
    @media screen and (max-width: 992px  ) {
        /* Slide Container */
        #MainSlideContainer{
            width:100%;
            min-height: 100%;
        }
    }

</style>
