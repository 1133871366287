<template>
    <div class="container" id="ProjectsSlideContainer">
        <div id="scrollerHider">
            <slide-title title="PROJECTS"></slide-title>

            <slide-project-card v-for="(project,title) in Projects" :info="project" :key="title"></slide-project-card>
        </div>
    </div>
</template>

<script>
    import title from '../ProjectsSlide/Sections/Title.vue'
    import projectCard from '../ProjectsSlide/Sections/ProjectCard.vue'
    export default {
        data(){
            return {
                Projects : {
                    "Quran Bot":{
                        name : "Telegram Quran Bot",
                        image : require("@/assets/images/Projects/reduced/bot.jpg"),
                        titleColor : "Black",
                        usedTech : ["PHP" , "Laravel" , "Composer" , "Git" , "Telegram API", "DigitalOcean" , "Ubuntu"],
                        team : ["Done Individually"],
                        source : "Closed.",
                        snapshots : {
                            "Image 1": require("@/assets/images/Projects/reduced/bot_snap_1.jpg"),
                            "Image 2": require("@/assets/images/Projects/reduced/bot_snap_2.jpg"),
                            "Image 3": require("@/assets/images/Projects/reduced/bot_snap_3.jpg"),
                            "Image 4": require("@/assets/images/Projects/reduced/bot_snap_4.jpg"),
                        },
                        desc : "Telegram Quran Bot is a quran-serving intuitive command-oriented bot that operate using telegram API ... you can check from here <a style='font-weight: bolder;text-decoration: none' href='http://quran-bot.com'> >> </a>"
                    },
                    "Mutasayib":{
                        name : "Mutasayib مُتسيب",
                        image : require("@/assets/images/Projects/reduced/mutasayib.png"),
                        titleColor : "DarkMagenta",
                        usedTech : ["Ionic" , "Javascript" , "Vue" , "Git" , "Capacitor" , "CSS"],
                        team : ["Done Individually"],
                        source : "Closed.",
                        snapshots : {
                            "Track Time In Arabic": require("@/assets/images/Projects/reduced/Mutasayib_1.jpg"),
                            "Track Time In English": require("@/assets/images/Projects/reduced/Mutasayib_2.jpg"),
                            "Daily Track In English": require("@/assets/images/Projects/reduced/Mutasayib_3.jpg"),
                            "Daily Track In Arabic": require("@/assets/images/Projects/reduced/Mutasayib_4.jpg"),
                            "Manual Edit": require("@/assets/images/Projects/reduced/Mutasayib_5.jpg"),
                        },
                        desc : "Mutasayib is a time tracking app for stc contractors ... that allow them to track their attendances through the SMS messages recived on daily fingureprints " +
                            "you can download the apk from here <a style='font-weight: bolder;text-decoration: none' href='files/Mutasayib.apk'> >> </a>"
                    },
                    "YouTubeVideoGenerator":{
                        name : "YouTube Video Generator",
                        image : require("@/assets/images/Projects/reduced/generator_snap_1.jpg"),
                        titleColor : "White",
                        usedTech : ["Java" , "JShell" ,"Spring Boot" , "FFMpeg" , "Maven" , "Git" ],
                        team : ["Done Individually"],
                        source : "Closed.",
                        snapshots : {
                            "Image 1": require("@/assets/images/Projects/reduced/generator_snap_1.jpg"),
                            "Image 2": require("@/assets/images/Projects/reduced/generator_snap_2.jpg"),
                            "Image 3": require("@/assets/images/Projects/reduced/generator_snap_3.jpg"),
                            "Image 4": require("@/assets/images/Projects/reduced/generator_snap_4.png"),
                        },
                        desc : "YouTube Video Generator is a shell command tool ... that i built to help me generate quran video to upload in <a href='https://www.youtube.com/channel/UCOtn5lHaRHuO40zFV8ZYX5A'>youtube channel</a>" +
                            "<br><br>" +
                            "It has a lot of features like listing all reciters in MP3Quran , autocomplition , ... "
                    },
                    "PersonalWebsite":{
                        name : "PersonalWebsite",
                        image : require("@/assets/images/Projects/reduced/reduced_before_personalwebsite.jpg"),
                        titleColor : "Black",
                        usedTech : ["PHP" , "Javascript" ,"HTML5" , "CSS3", "Laravel" , "VueJs" , "JQuery" , "Composer" , "NPM" , "Git" , "Vagrant" , "DigitalOcean" , "Ubuntu"],
                        team : ["Done Individually"],
                        source : "Closed.",
                        snapshots : {
                            "v1": require("@/assets/images/Projects/reduced/reduced_before_personalwebsite.jpg"),
                            "v2": require("@/assets/images/Projects/reduced/personalWebsite_v2.png"),
                        },
                        desc : "Personal Website is an online Resume/CV to show my set of skills in more extensive way and the accomplished projects\n" +
                            "in addition to give them short introduction about me ."
                    },
                    // "FaselHDExtension":{
                    //     name : "FaselHDExtension",
                    //     image : require("@/assets/images/Projects/reduced/reduced_after_faselhdextension.jpg"),
                    //     titleColor : "rgba(255, 255, 255, 0.8)",
                    //     usedTech : ["Chrome Extension" , "Javascript" , "HTML5" , "CSS3" , "JQuery" , "Bootstrap", "NPM" ],
                    //     team : ["Done Individually"],
                    //     source : "Closed.",
                    //     snapshots : {
                    //         "v1": require("@/assets/images/Projects/reduced/reduced_before_faselhdextension2.jpg"),
                    //     },
                    //     desc : "FaselHDExtension is a chrome extension that allow me to download complete series of Tv Show from FaselHD Website "
                    // },
                    "EmotionalFacialRecognition":{
                        name : "EmotionalFacialRecognition",
                        image : require("@/assets/images/Projects/reduced/reduced_before_fer_2.jpg"),
                        titleColor : "rgba(255, 255, 255, 0.8)",
                        usedTech : ["Python" , "TkInter" , "OpenCV" , "Pip" , "Ubuntu" , "Git"],
                        team : ["Mohammed Balhaddad" , "Majid Saeedan", "Musaed Albrikan"],
                        source : "Closed.",
                        snapshots : {
                            "Image 1": require("@/assets/images/Projects/reduced/reduced_before_fer.jpg"),
                            "Image 2": require("@/assets/images/Projects/reduced/reduced_before_fer_3.jpg"),
                        },
                        desc : "EmotionalFacialRecognition uses AI to anticipate the emotion of detected faces in the image taken fom the camera"
                    },
                    // "ImageWatermarker":{
                    //     name : "ImageWatermarker",
                    //     image : require("@/assets/images/Projects/reduced/reduced_after_imagewatermarker.jpg"),
                    //     titleColor : "rgba(255, 255, 255, 0.8)",
                    //     usedTech : ["Java" , "Android Development" , "OpenCV" , "Maven" , "XML" ],
                    //     team : ["Mohammed Balhadad" , "Abdulrahman Abdullah Humayed", "Abdulsalam Almasri"],
                    //     source : "Closed.",
                    //     snapshots : {
                    //         "v1": require("@/assets/images/Projects/reduced/reduced_before_imagewatermarker.jpg")
                    //     },
                    //     desc : "ImageWatermarker uses image manipulation algorithms to convert image to energy domain form pixel domain then inject watermark after that" +
                    //         " convert it back to pixel domain to prevent watermark distortion ."
                    // },
                },
            };
        },
        components:{
            'slide-title' : title,
            'slide-project-card' : projectCard ,
        },
    }
</script>

<style scoped>
    #ProjectsSlideContainer{
        background-color: #4a8fa2;
        color: white;
    }

    @media screen and (min-width: 992px  ) {
        #ProjectsSlideContainer{
            display: none;
            width:50%;
            margin:0 auto;
            position: absolute;
            left: 25%;
            z-index: 1;
            overflow: hidden;
            height: 100%;
        }
        #scrollerHider{
            overflow: auto;
            width:100%;
            height: 100%;
            position: absolute;
            padding-right: 20px;
            padding-top: 20px;
        }
    }

    @media screen and (max-width: 992px  ) {
        #ProjectsSlideContainer{
            width:100%;
            min-height: 100%;
        }
    }



</style>
