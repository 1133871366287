<template>
    <div id="skillGroupContainer">
        <!-- Title of Skills Group-->
        <slide-title :title="title"></slide-title>
        <app-skill v-for="(currentSkill,key) in skills" :currentSkill="currentSkill" :key="key"></app-skill>
    </div>
</template>

<script>
    import skill from './Skill.vue'
    import title from './Title.vue'
    export default {
        props:['skills','title'],
        components:{
            'app-skill' : skill,
            'slide-title' : title
        }
    }
</script>

<style scoped>
    #skillGroupContainer{
        padding-bottom: 50px;
        margin-left: 5%;
    }

    @media screen and (min-width: 2400px ) {
        #skillGroupContainer{
            margin: 0 auto;
            padding-left: 5%;
            max-width: 1200px ;
        }
    }

</style>
