import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
window.$ = window.jQuery = require('jquery');

require('@/assets/css/Nunito.css');
require('@/assets/css/fontello.css');

new Vue({
  render: h => h(App),
}).$mount('#app')
