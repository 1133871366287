<template>
    <div class="container" id="AboutSlideContainer">
        <div id="scrollerHider">
            <div id="AboutBody">
                <div id="textWrapper">
                    <!-- < Mohammed > -->
                    <span class="number" v-html="getLineNumberText(1)"></span>
                    <span class="tag"> &lt;</span> Mohammed <span style="color: #aeb3ba">&gt;</span><br>

                    <!-- Additional space -->
                    <span class="number" v-html="getLineNumberText(2)"></span>

                    <!-- life -->
                    <div v-for="tag in life.children" :key="tag.tag.value">
                        <tag :tag="tag"></tag>
                    </div>

                    <!-- </ Balhaddad > -->
                    <span class="number" v-html="getLineNumberText(46)"></span>
                    <span class="tag"> &lt;&sol;</span> Balhaddad <span style="color: #aeb3ba">&gt;</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Tag from './Sections/Tag'
    export default {
        components : {
            'tag'       : Tag
        },
        data : function (){
            return {
                space:'&nbsp;',
                life : {
                    tag : {
                        value : 'Mohammed',
                        number : 0 ,
                    },
                    closeTag : {
                        value : 'Balhaddad',
                        number : 0,
                    },
                    children : [
                        {
                            comment : {
                                value : 'general info' ,
                                number : 0 ,
                            },
                            tag : {
                                value : 'about',
                                number : 0 ,
                            },
                            properties : [
                                {
                                    key : 'status',
                                    value : 'married',
                                    number : 0 ,
                                },
                                {
                                    key : 'kids',
                                    value : 'yes',
                                    number : 0 ,
                                },
                                {
                                    key : 'employment',
                                    value: 'yes',
                                    number : 0 ,
                                },
                                {
                                    key : 'company',
                                    value: 'STC',
                                    number : 0 ,
                                },
                                {
                                    key : 'since',
                                    value: '2019',
                                    number : 0 ,
                                },
                                {
                                    key : 'title',
                                    value: 'Senior System Developer',
                                    number : 0 ,
                                },
                                {
                                    key : 'freelancing',
                                    value: 'yes',
                                    number : 0 ,
                                },
                                {
                                    key : 'contact',
                                    value: '+966567388531',
                                    number : 0 ,
                                },
                                {
                                    key : 'resume',
                                    value: '<a href="files/CV v.8.pdf" style="text-decoration: none;color: lightgray">>></a>',
                                    number : 0 ,
                                }
                            ]
                        },
                        {
                            comment : {
                                value : 'my degrees' ,
                                number : 0 ,
                            },
                            tag : {
                                value : 'degrees',
                                number : 0 ,
                            },
                            properties : [
                                {
                                    key : 'bachelor',
                                    value : 'KFUPM',
                                    number : 0 ,
                                }
                            ]
                        },
                        {
                            comment : {
                                value : 'my awards' ,
                                number : 0 ,
                            },
                            tag : {
                                value : 'awards',
                                number : 0 ,
                            },
                            properties : [
                                {
                                    comment : {
                                        value : 'by saudi TVTC' ,
                                        number : 0 ,
                                    },
                                    key : 'SaudisSkills@2015',
                                    value : 'golden medal',
                                    number : 0 ,
                                },
                                {
                                    comment : {
                                        value : 'by KFUPM' ,
                                        number : 0 ,
                                    },
                                    key : 'GraduationProject@2018',
                                    value : 'First position',
                                    number : 0 ,
                                },
                                {
                                    comment : {
                                        value : 'by thakaa center' ,
                                        number : 0 ,
                                    },
                                    key : 'Cybersecurity@2021',
                                    value : 'Silver medal',
                                    number : 0 ,
                                },
                            ]
                        },
                        {
                            comment : {
                                value : 'i have a youtube channel too 🤯' ,
                                number : 0 ,
                            },
                            tag : {
                                value : 'teach',
                                number : 0 ,
                            },
                            properties : [
                                {
                                    key : 'at',
                                    value : '@CipheredCall',
                                    number : 0 ,
                                },
                                {
                                    key : 'subjects',
                                    value : "java, spring , javascript",
                                    number : 0 ,
                                }
                            ]
                        },
                        {
                            comment : {
                                value : 'things i love' ,
                                number : 0 ,
                            },
                            tag : {
                                value : 'love',
                                number : 0 ,
                            },
                            properties : [
                                {
                                    key : 'programming',
                                    number : 0 ,
                                },
                                {
                                    key : 'tea/coffee',
                                    number : 0 ,
                                },
                                {
                                    key : 'reading',
                                    number : 0 ,
                                }
                            ]
                        },
                        {
                            comment : {
                                value : 'soft skills i possess' ,
                                number : 0 ,
                            },
                            tag : {
                                value : 'skills',
                                number : 0 ,
                            },
                            properties : [
                                {
                                    key : 'TeamPlayer',
                                    number : 0 ,
                                },
                                {
                                    key : 'ProblemSolving',
                                    number : 0 ,
                                },
                                {
                                    key : 'SelfLearner',
                                    number : 0 ,
                                },
                                {
                                    key : 'SelfMotivator',
                                    number : 0 ,
                                }
                            ]
                        },
                    ]
                },
            };
        },
        methods:{
            calculateLineNumbers(){
                let counter = 3 ; // start at line 3

                // loop on each child
                this.life.children.forEach(function (child){
                    // check if child has comment ( comment in separate line number )
                    if (child.comment != null)
                        child.comment.number = counter++ ;

                    // off course , child will have tag --> assign it number
                    child.tag.number = counter++;

                    // loop on each property for this child
                    child.properties.forEach(function (property){
                        // check of property has comment
                        if (property.comment != null)
                            property.comment.number = counter++ ;

                        // add line number for the property itself
                        property.number = counter++ ;
                    });

                    // there will be space between each tag
                    counter++;
                });
                
            },
            getLineNumberText(number) {
                if (number < 10){
                    return number + this.space.repeat(2) ;
                }else {
                    return number + this.space ;
                }
            }
        },
        mounted() {
            // assign the line number for each line
            this.calculateLineNumbers();
        }
    }
</script>

<style scoped>
    /* Common Styling */
    .number{
        padding-left:4px;
        padding-top:6px;
        font-weight: normal;
        font-size: large;
        background-color: #4f4f4f;
    }
    .tag{
        color: #aeb3ba;
    }
    #AboutSlideContainer{
        background-color: #323232FF;
        height: 100%;
    }
    #AboutBody {
        width: 100%;
    }
    #AboutBody #textWrapper {
        width: 96%;
        background-color: #323232FF;
        padding: 2%;
        white-space: nowrap;
        overflow-y: auto;
        line-height:24px;
        font-family: 'Courier New',monospace;
        font-weight: bold;
        font-size: 17px;
    }
    /* Slider View */
    @media screen and (min-width: 992px ){
        #AboutSlideContainer{
            display: none;
            width:50%;
            position: absolute;
            left: 25%;
            z-index: 3;
            color: white;
            overflow: hidden;
        }
        #scrollerHider{
            overflow: auto;
            width:100%;
            height: 100%;
            position: absolute;
            padding-right: 20px;
        }
        #AboutBody{
            height: 100%;
            text-align: justify;
        }
        #AboutBody #textWrapper {
            min-height: 100%;
            font-weight: bolder;
            font-size: x-large;
            font-family: monospace;
        }
    }
    /* Rolling View */
    @media screen and (max-width: 992px  ) {
        /* Applied on all sub views */
        #AboutSlideContainer{
            width:100%;
        }
        #AboutBody #textWrapper {
            font-weight: bolder;
            font-family: monospace;
            color: white;
        }
    }

</style>
