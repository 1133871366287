<template>
    <div class="skill" >
        <div id="skillName">
            <p>{{currentSkill.name}}</p>
        </div>
        <circle-progress-bar :percentage="currentSkill.pers"></circle-progress-bar>
    </div>
</template>

<script>
    import circle from './CircleProgressBar.vue'
    export default {
        props:['currentSkill'],
        components : {
            'circle-progress-bar' : circle
        }
    }
</script>

<style scoped>
    .skill{
        display: inline-block;
        width:28%;
        margin-left: 2%;
        margin-bottom: 2%;
        padding: 0;
        border: 1px solid white;
        border-radius: 2px ;
    }
    #skillName{
        text-align: center;
        border-bottom: 1px solid white;
        height: 40px;
        line-height: 15px;
        overflow: hidden ;
    }
</style>
