<template>
    <div class="container" id="SkillsSlideContainer">
        <div id="scrollerHider">
            <skills-group v-for="(skill, key) in skills" :skills="skill" :title="key" :key="key"></skills-group>
        </div>
    </div>

</template>

<script>
    import SkillsGroup from './Sections/SkillsGroup.vue'
    export default {
        data(){
            return {
                // this should be props passed from laravel inside blade page
                skills : {
                    "Languages":[
                        {name : "Java" , pers : 80},
                        {name : "PHP" , pers : 75},
                        {name : "Javascript" , pers : 70},
                        {name : "SQL" , pers : 70},
                    ],
                    "Frameworks":[
                        {name : "Spring" , pers : 85},
                        {name : "Laravel" , pers : 90},
                        {name : "VueJs" , pers : 80},
                    ],
                    "Testing":[
                        {name : "Jest" , pers : 75},
                        {name : "JUnit" , pers : 80},
                        {name : "Mockito" , pers : 70},
                        {name : "Vue Test Utils" , pers : 75},
                    ],
                    "Dependency Manager":[
                        {name : "Composer" , pers : 80},
                        {name : "NPM" , pers : 75},
                        {name : "Maven" , pers : 75},
                    ],
                    "DevOps":[
                        {name : "Git & Github" , pers : 80},
                        {name : "Vagrant" , pers : 80},
                        {name : "Docker" , pers : 65},
                        {name : "Linux" , pers : 75},
                    ],
                    "OAuth" :[
                        {name : "OAuth" , pers : 95},
                        {name : "JWT" , pers : 95},
                        {name : "OpenID" , pers : 70},
                    ],
                }
            };
        },
        components :{
            'skills-group' : SkillsGroup,
        }
    }
</script>

<style scoped>
    #SkillsSlideContainer{
        background-color: #c76969;
        color: white;
    }
    h1{
        margin-top: 0;
    }
    p{
        margin-bottom: 0;
        padding: 50px;
    }
    @media screen and (min-width: 992px ) {
        #SkillsSlideContainer{
            display: none;
            width:50%;
            margin:0 auto;
            position: absolute;
            left: 25%;
            z-index: 2;
            overflow: hidden;
            height: 100%;
        }
        #scrollerHider{
            overflow: auto;
            width:100%;
            height: 100%;
            position: absolute;
            padding:10px;
        }
    }

    @media screen and (max-width: 992px ) {
        #SkillsSlideContainer{
            width:100%;
            min-height: 100%;
        }
    }



</style>
