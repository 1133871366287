<template>
    <div>
        <main-slide></main-slide>
        <about-slide></about-slide>
        <skills-slide></skills-slide>
        <projects-slide></projects-slide>
        <contact-slide></contact-slide>
    </div>
</template>


<script>
import MainSlid from './components/MainSlide/MainSlide.vue';
import AboutSlide from './components/AboutSlide/AboutSlide.vue';
import SkillsSlide from './components/SkillsSlide/SkillsSlide.vue';

import ProjectsSlide from './components/ProjectsSlide/ProjectsSlide.vue';
import ContactSlide from './components/ContactSlide/ContactSlide.vue';

export default {
    name: 'App',
    components: {
        'main-slide': MainSlid,
        'about-slide': AboutSlide,
        'skills-slide': SkillsSlide,
        'projects-slide': ProjectsSlide,
        'contact-slide': ContactSlide,
    }
}

</script>

<style>
    #app {
        display: block;
        width: 100%;
        height: 100%;

    }

    .successMessage {
        display: block;
        border: darkgreen 1px solid;
        background-color: rgba(102, 213, 89, 0.52);
        margin: 30px auto;
        position: fixed;
        z-index: 100;
        text-align: center;
        color: darkgreen;
        font-family: "Nunito";
        font-weight: bold;
        padding: 10px 0;
        line-height: 30px;
    }

    @media screen and (min-width: 992px  ) {
        .successMessage {
            width: 40%;
            right: 30%;
            font-size: 16px;
        }
    }

    @media screen and (max-width: 992px ) and (min-width: 500px ) {
        .successMessage {
            width: 80%;
            right: 10%;
            font-size: 16px;
        }
    }

    @media screen and (max-width: 500px ) {
        .successMessage {
            width: 90%;
            right: 5%;
            font-size: 10px;
        }
    }
</style>
