<template>
    <div id="TitleHeader">
        <h2>
            <span>------------</span>
            {{title}}
            <span>------------</span>
        </h2>
    </div>
</template>

<script>
    export default {
        props : ['title'],
    }
</script>

<style scoped>
    #TitleHeader{
        padding:30px 0;
        display: block;
        text-align: center
    }
    #TitleHeader h2{
        display: inline-block;
    }
    #TitleHeader h2 span:first-child{
        margin-right:15px;
        text-align:right;
        width:100px;
        height:30px;
        line-height:30px;
        border-right: 2px solid white;
        overflow: hidden;
    }
    #TitleHeader h2 span:last-child{
        margin-left:15px;
        text-align:left;
        width:100px;
        height:30px;
        line-height:30px;
        border-left: 2px solid white;
        overflow: hidden;
    }

    /* Title brackets removed at this size*/
    @media screen and (max-width: 623px) ,  (min-width: 992px ) and (max-width: 1163px){ /*Between these sizes , we have small slides*/
        div>h2 {
            border: 1px solid white;
            padding: 10px 30px;
            background-color: white;
            color: #de5b7b;
            border-radius: 10px;
        }
        div>h2>span{
            display: none;
        }
    }

</style>
