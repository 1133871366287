<template>
    <div class="container" id="ContactSlideContainer">
        <div id="scrollerHider">
            <!-- Title of Contact -->
            <slide-title title="CONTACT ME"></slide-title>
            <p>
                Please never hesitate to contact me ... <i class="far fa-grin-wink"></i> <br>
                Based on the subject , please choose one of the following channels ...
            </p>
            <p style="text-align: center;width: 400px;display:block;margin:0 auto;padding-top:30px;padding-bottom: 100px">
                <!-- General -->
                General ( <a href="mailto:mb@mbalhaddad.com"><i class="fas fa-envelope"></i></a>
                , <a href="https://twitter.com/messages/compose?recipient_id=1012440901&text=السلام%20عليكم"><i class="fab fa-twitter"></i></a>
                , <a href="https://wa.me/966500245215?text=السلام%20عليكم"><i class="fab fa-whatsapp"></i></a>
                , <a href="https://telegram.me/MohammedDeveloper"><i class="fab fa-telegram-plane"></i></a>
                ) <br><br>

                <!-- Ciphered Calls -->
                Ciphered Calls (
                    <a href="https://twitter.com/messages/compose?recipient_id=1444857860953944066&text=السلام%20عليكم"><i class="fab fa-twitter"></i></a>
                ) <br><br>

                <!-- Quran Bot -->
                Quran Bot (
                    <a href="https://twitter.com/messages/compose?recipient_id=1256260097967349760&text=السلام%20عليكم"><i class="fab fa-twitter"></i></a>
                ) <br>
            </p>
        </div>
    </div>
</template>


<script>
    import title from '../ContactSlide/Sections/Title.vue'
    export default {
        data(){
            return {

            };
        },
        props:['csrf'],
        computed:{

        },
        components:{
            'slide-title' : title
        }
    }
</script>

<style scoped>
    #ContactSlideContainer{
        background-color: #20716a;
        color: white;
    }

    /* the small Paragraph */
    p{
        text-align: center;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    p a{
        color: white;
        text-decoration: none;
    }


    @media screen and (min-width: 992px  ) {
        #ContactSlideContainer{
            display: none;
            width:50%;
            margin:0 auto;
            position: absolute;
            left: 25%;
            z-index: 0;
            overflow: hidden;
            height: 100%;
        }
        #scrollerHider{
            overflow: auto;
            width:100%;
            height: 100%;
            position: absolute;
            padding-right: 20px;
            padding-top: 20px;
        }
    }

    @media screen and (max-width: 992px  ) {
        #ContactSlideContainer{
            width:100%;
            min-height: 100%;
        }
    }



</style>
