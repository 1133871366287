<template>
    <div>
        <!-- Comment  -->
        <span class="number" v-html="getLineNumberText(tag.comment.number)"></span>
        <span v-html="indent(5)"></span>
        <span class="comment">&lt;!-- {{tag.comment.value}} --&gt;</span><br>

        <!-- Open Tag -->
        <span class="number" v-html="getLineNumberText(tag.tag.number)"></span>
        <span v-html="indent(5)"></span>
        <span class="tag">&lt;</span> {{tag.tag.value}}<br>

            <!-- Tag Properties -->
            <span v-for="(property,index) in tag.properties" :key="property.key">
                <!-- Comment -->
                <span v-if="property.comment != null">
                    <span class="number" v-html="getLineNumberText(property.comment.number)"></span>
                    <span v-html="indent(7)"></span>
                    <span class="comment">&lt;!-- {{property.comment.value}} --&gt;</span><br>
                </span>

                <!-- Line Number -->
                <span class="number" v-html="getLineNumberText(property.number)"></span>

                <!-- Indentation -->
                <span v-html="indent(7)"></span>

                <!-- Property -->
                <span class="property-key"> {{property.key}} </span>
                <span v-if="property.value">
                    =<span class="property-value"> "<span v-html="property.value"></span>"</span>
                </span>

                <!-- Add newLine if not last property -->
                <br v-if="index !== tag.properties.length - 1">
            </span>

        <!-- Close Tag -->
        <span class="tag"> &sol;&gt;</span><br>

        <!-- Space after the tag -->
        <span class="number" v-html="getLineNumberTextAfterTag(tag)"></span>
    </div>
</template>

<script>
export default {
    name: "Tag",
    props:['tag'],
    data(){
        return{
            space:'&nbsp;',
        };
    },
    methods:{
        getLineNumberText(number) {
            if (number < 10){
                return number + this.indent(2) ;
            }else {
                return number + this.indent(1) ;
            }
        },
        getLineNumberTextAfterTag(tag) {
            let number = tag.properties[tag.properties.length - 1].number + 1 ;
            return this.getLineNumberText(number);
        },
        indent(times){
            return this.space.repeat(times);
        }
    }
}
</script>

<style scoped>
    .number{
        padding-left:4px;
        padding-top:6px;
        font-weight: normal;
        font-size: large;
        background-color: #4f4f4f;
    }
    .tag{
        color: #aeb3ba;
    }
    .comment{
        color:#9d9fa1;
        font-weight: lighter;
    }
    .property-key{
        color:#903c51;
    }
    .property-value{
        color:lightgreen
    }
    @media screen and (max-width: 1100px ) and (min-width: 992px ){
        div{
            /*width: 600px ;*/
            font-size: large;
        }
    }
    @media screen and (max-width: 992px ) and (min-width: 650px ){
        div{
            /*width: 600px ;*/
            font-size: large;
        }
    }
    @media screen and (max-width: 650px ) and (min-width: 500px  )  {
        div{
            /*width: 450px ;*/
            font-size: medium;
        }
    }
    @media screen and (max-width: 500px  ) and (min-width: 400px  ){
        div{
            /*width: 350px ;*/
            font-size:small;
        }
    }
    @media screen and (max-width: 400px  ) {
        div{
            /*width: 350px ;*/
            font-size:x-small;
        }
    }
</style>